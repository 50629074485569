<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="!$auth.isAuthenticated">
          <p class="mb-2">Please click the button below to log in and start the adventure</p>
        </v-card-text>

        <!-- login / logout -->
        <v-card-title v-if="!$auth.loading" class="d-flex align-center justify-center py-7">
          <div class="align-center text-center">
            <!-- show login when not authenticated -->
            <v-btn v-if="!$auth.isAuthenticated" @click="login">
              <v-icon color="primary" :size="30">
                {{ icons.mdiLoginVariant }}
              </v-icon>
              <span class="ml-2">Log in</span>
            </v-btn>
            <!-- show logout when authenticated -->
            <p v-if="$auth.isAuthenticated" class="text-xl-body-1 font-weight-semibold mb-2">
              Welcome back, {{ $auth.user.given_name }} {{ $auth.user.family_name }}! 👋🏻
              <v-btn class="mt-5" @click="logout">
                <v-icon color="primary" :size="30">
                  {{ icons.mdiLogoutVariant }}
                </v-icon>
                <span class="ml-2">Log out</span>
              </v-btn>
            </p>
          </div>
        </v-card-title>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider color="primary"></v-divider>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiLoginVariant, mdiLogoutVariant } from "@mdi/js"
import themeConfig from "@themeConfig"

export default {
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect()
    },

    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiLoginVariant,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
</style>
